import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import alertModalStyles from "./AlertModal.module.scss";

class AlertModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.handleButtonCloseModal = this.handleButtonCloseModal.bind(this);
  }

  handleButtonCloseModal() {
    this.setState({showModal: false});
    this.props.setShowModal(false);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.show !== this.props.show) {
        this.setState({showModal: this.props.show});
    }
  }

  render() {
    return (
      <>
        <Modal show={this.state.showModal} centered={true} contentClassName={alertModalStyles.alertModalCustom}>
          <Modal.Body>
            <p>Anda belum/tidak mengisi data NPWP. Silahkan pilih salah satu alasan tidak mengisi data NPWP.</p>
          </Modal.Body>
          <div className={alertModalStyles.alertModalFooterCustom}>
            <Button variant="primary" onClick={this.handleButtonCloseModal}>Tutup</Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AlertModal;
