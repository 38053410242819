import $ from 'jquery';

export default function validate(values, form, lang) {
	let errors = {};
	$(form).find('input,textarea,select').each(function(index, el) {
		// if (el.validationMessage) {
		// 	errors[el.name] = el.validationMessage;
		// }
		// console.log(el.name, el.value.length < el.maxLength ? (el.maxLength - el.value.length) : el.maxLength)
		// console.log(el.name, el.maxLength, el.pattern, el.validity, el.willValidate, el.validationMessage)
		// console.log(el.type);
		if (el.validationMessage) {
			if (el.validity.valueMissing) {
				errors[el.name] = 'error_valueMissing';
			} else if (el.validity.patternMismatch) {
				errors[el.name] = 'error_patternMismatch';
			} else if (el.validity.tooShort) {
				errors[el.name] = {
					type: 'error_tooShort',
					param: el.minLength,
					param2: el.value.length + '/' + el.maxLength
				};
			} else if (el.validity.tooLong) {
				errors[el.name] = 'error_tooLong';
			} else if (el.validity.rangeOverflow) {
				errors[el.name] = 'error_'+ el.type +'_rangeOverflow';
			} else if (el.validity.rangeUnderflow) {
				errors[el.name] = 'error_'+ el.type +'_rangeUnderflow';
			} else if (el.validity.stepMismatch) {
				errors[el.name] = 'error_stepMismatch';
			} else if (el.validity.typeMismatch) {
				errors[el.name] = 'error_typeMismatch';
			} else if (el.validity.customError) {
				errors[el.name] = 'error_customError';
			} else {
				errors[el.name] = 'error_elseError';
			}
		}
	});
	return errors;
}