import React, {useContext} from 'react';
import {RootContext} from '../Home';
import YouTube from 'react-youtube';
import logo from './../assets/logo-korindo.png';
import './MaintenancePage.scss';

const MaintenancePage = (props) => {
	const store = useContext(RootContext);
  	return (
	    <div className="maintenance-page">
	    	<div className="wrapper">
		    	<div className="logo">
					<img src={logo} className="img-fluid" alt=""/>
				</div>

		    	<h2 className="title">Under Maintenance</h2>
		    	<p className="desc">NH Korindo Sekuritas is down for scheduled maintenance and expect to back online in a few minutes.</p>
		    	{
	                store.state.setting_contact.length > 0 ? 
	                <YouTube containerClassName="video" videoId={store.state.setting_contact.find(({key}) => key === 'featured_youtube_video').value} opts={store.state.optYoutube} />
	                :
	                ''
	            }
		    	<p className="info">
		    		In the meantime checkout to our website.<br/>
		    		<a href="https://www.nhsec.co.id/">https://www.nhsec.co.id/</a>
		    	</p>
	    	</div>
	    </div>
  	)
}

export default MaintenancePage;