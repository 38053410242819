import React, { Component, Fragment } from 'react';
import ButtonCustom from '../components/mini/ButtonCustom';
import Form from 'react-bootstrap/Form';
import validate from '../pages/validate';
import { RootContext } from '../Home';
import cx from 'classnames';
import styleRegis from '../pages/RegistrationPage.module.scss';
import { Formik } from 'formik';
import CustomInput from '../components/mini/CustomInput';
import * as Yup from "yup";
import { isMobile } from "react-device-detect";


function array_highlight(newSource, newHighlight) {
    let source = newSource;
    let highlight = newHighlight;
    let newArray = [];

    for (let k = 0; k < highlight.length; k++) {
        newArray.push(source.find(({ value }) => value === highlight[k]))
    }

    for (let i = 0; i < source.length; i++) {
        for (let j = 0; j < highlight.length; j++) {
            if (source[i].value !== highlight[j] && !newArray.find(({ value }) => value === source[i].value)) {
                newArray.push(source[i]);
                break;
            }
        }
    }
    return newArray;
}

export default class GeneralInformationForm extends Component {
    render() {
        return (
            <RootContext.Consumer>
                {
                    store => {
                        // const schema = !isMobileOnly ? Yup.object().shape({
                        //     nationality: Yup.string().required('error_valueMissing').nullable(),
                        //     educational_background: Yup.object().required('error_valueMissing').nullable(),
                        //     income_per_annum: Yup.object().required('error_valueMissing').nullable(),
                        //     source_of_fund: Yup.object().required('error_valueMissing').nullable(),
                        // })
                        //     : '';

                        const schema = !isMobile ? Yup.object().shape({
                            nationality: Yup.string().required('error_valueMissing').nullable(),
                            educational_background: Yup.object().required('error_valueMissing').nullable(),
                            income_per_annum: Yup.object().required('error_valueMissing').nullable(),
                            source_of_fund: Yup.object().required('error_valueMissing').nullable(),
                        })
                            : '';                        

                        let masterMaritalStatus = store.state.master.master_marital_status.slice();
                        if (store.state.registration.sex) {
                            if (store.state.registration.sex === 'male') {
                                masterMaritalStatus.splice('3', 1);
                            } else {
                                masterMaritalStatus.splice('2', 1);
                            }
                        }

                        const countrySelected = [104, 119, 135, 200, 46];
                        let masterNationality = array_highlight(store.state.master.master_nationality_ksei, countrySelected);
                        masterNationality = masterNationality.splice(1, masterNationality.length);
                        return (
                            <Formik
                                validate={(values) => validate(values, '#general-information')}
                                validationSchema={schema}
                                onSubmit={values => {
                                    // console.log(values)
                                    store.dispatch({ type: 'handleSubmit', field: 'registration_general_information', values: values })
                                }}
                                initialValues={{
                                    first_name: store.state.registration.first_name,
                                    middle_name: store.state.registration.middle_name,
                                    last_name: store.state.registration.last_name,
                                    nationality: store.state.registration.nationality,
                                    place_of_birth: store.state.registration.place_of_birth,
                                    date_of_birth: store.state.registration.date_of_birth,
                                    sex: store.state.registration.sex,
                                    marital_status: store.state.registration.marital_status,
                                    spouse_name: store.state.registration.spouse_name,
                                    mother_maiden_name: store.state.registration.mother_maiden_name,
                                    religion: store.state.registration.religion,
                                    religion_other: store.state.registration.religion_other,
                                    heir_name: store.state.registration.heir_name,
                                    heir_relation: store.state.registration.heir_relation,
                                    educational_background: store.state.registration.educational_background,
                                    // occupation: store.state.registration.occupation,
                                    // nature_of_business: store.state.registration.nature_of_business,
                                    // occupation_others: store.state.registration.occupation_others,
                                    income_per_annum: store.state.registration.income_per_annum,
                                    source_of_fund: store.state.registration.source_of_fund,
                                    source_of_fund_others: store.state.registration.source_of_fund_others,
                                    investment_objectives: store.state.registration.investment_objectives,
                                    asset_owner: store.state.registration.asset_owner,
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    isSubmitting,
                                    touched,
                                    isValid,
                                    errors,
                                    setFieldValue,
                                    setFieldTouched,
                                }) => (
                                    <Fragment>
                                        <Form id="general-information" className="form" validated={errors ? false : true} noValidate onSubmit={handleSubmit}>
                                            {
                                                /*
                                                <Form.Row>
                                                    <pre
                                                        className="col-lg-12"
                                                        style={{
                                                            background: '#f6f8fa',
                                                            fontSize: '.65rem',
                                                            padding: '.5rem',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <strong>props</strong> ={' '}
                                                        {JSON.stringify(values, null, 2)}
                                                        <br />
                                                        <strong>errors</strong> ={' '}
                                                        {JSON.stringify(errors, null, 2)}
                                                        <br />
                                                        <strong>touched</strong> ={' '}
                                                        {JSON.stringify(touched, null, 2)}
                                                        <br />
                                                    </pre>
                                                </Form.Row>
*/
                                            }
                                            <Form.Row>
                                                <CustomInput
                                                    col="4"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'first_name' })}
                                                    required
                                                    readOnly
                                                    name="first_name"
                                                    type="text"
                                                    maxLength="100"
                                                    pattern="^[A-Z a-z]+"
                                                    value={values.first_name}
                                                    handleChange={handleChange}
                                                    isValid={touched.first_name && !errors.first_name}
                                                    isInvalid={!!errors.first_name}
                                                    error={(typeof errors.first_name === 'object') ? store.dispatch({ type: 'translate', 'text': errors.first_name.type }).toString().replace('$param$', errors.first_name.param).replace('$param2$', errors.first_name.param) : store.dispatch({ type: 'translate', 'text': errors.first_name })}
                                                />

                                                <CustomInput
                                                    col="4"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'last_name' })}
                                                    name="last_name"
                                                    type="text"
                                                    readOnly
                                                    maxLength="100"
                                                    pattern="^[A-Z a-z]+"
                                                    value={values.last_name}
                                                    handleChange={handleChange}
                                                    isValid={touched.last_name && !errors.last_name}
                                                    isInvalid={!!errors.last_name}
                                                    error={(typeof errors.last_name === 'object') ? store.dispatch({ type: 'translate', 'text': errors.last_name.type }).toString().replace('$param$', errors.last_name.param).replace('$param2$', errors.last_name.param) : store.dispatch({ type: 'translate', 'text': errors.last_name })}
                                                />
                                            </Form.Row>

                                            <Form.Row>
                                                {
                                                    !store.state.citizenship.foreigner ?
                                                        <Fragment>
                                                            <input type="text" style={{ display: 'none' }} name="nationality" value={values.nationality} onBlur={handleBlur} onChange={handleChange} required className="form-control" />
                                                            <CustomInput
                                                                col="4"
                                                                labelName={store.dispatch({ type: 'translate', 'text': 'nationality' })}
                                                                readOnly
                                                                type="text"
                                                                value="INDONESIAN"
                                                            />
                                                        </Fragment>
                                                        :
                                                        <CustomInput
                                                            col="4"
                                                            labelName={store.dispatch({ type: 'translate', 'text': 'nationality' })}
                                                            name="nationality"
                                                            type="select"
                                                            required
                                                            value={values.nationality}
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            placeholder={store.dispatch({ type: 'translate', 'text': 'placeholder_select' })}
                                                            error={(typeof errors.nationality === 'object') ? store.dispatch({ type: 'translate', 'text': errors.nationality.type }).toString().replace('$param$', errors.nationality.param).replace('$param2$', errors.nationality.param) : store.dispatch({ type: 'translate', 'text': errors.nationality })}
                                                            touched={touched.nationality}
                                                            option={masterNationality}
                                                            gap={countrySelected[countrySelected.length - 1]}
                                                        />
                                                }
                                                <CustomInput
                                                    col="4"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'pob' })}
                                                    name="place_of_birth"
                                                    type="text"
                                                    required
                                                    maxLength="100"
                                                    pattern="^[A-Z a-z]+"
                                                    info={!store.state.citizenship.foreigner ? store.dispatch({type:'translate', 'text': 'idenhelp'}) : false}
                                                    value={values.place_of_birth}
                                                    handleChange={(el) => {
                                                        let elVal = el.target.value ? el.target.value.toUpperCase() : "";
                                                        setFieldValue("place_of_birth", elVal);
                                                    }}
                                                    isValid={touched.place_of_birth && !errors.place_of_birth}
                                                    isInvalid={!!errors.place_of_birth}
                                                    error={(typeof errors.place_of_birth === 'object') ? store.dispatch({ type: 'translate', 'text': errors.place_of_birth.type }).toString().replace('$param$', errors.place_of_birth.param).replace('$param2$', errors.place_of_birth.param) : store.dispatch({ type: 'translate', 'text': errors.place_of_birth })}
                                                />

                                                <CustomInput
                                                    col="4"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'dob' })}
                                                    required
                                                    readOnly
                                                    name="date_of_birth"
                                                    type="date"
                                                    dateType="birthdate"
                                                    value={values.date_of_birth}
                                                    handleChange={date => setFieldValue('date_of_birth', date)}
                                                    isValid={touched.date_of_birth && !errors.date_of_birth}
                                                    isInvalid={!!errors.date_of_birth}
                                                    error={(typeof errors.date_of_birth === 'object') ? store.dispatch({ type: 'translate', 'text': errors.date_of_birth.type }).toString().replace('$param$', errors.date_of_birth.param).replace('$param2$', errors.date_of_birth.param) : store.dispatch({ type: 'translate', 'text': errors.date_of_birth })}
                                                    placeholder="DD/MM/YYYY"
                                                />
                                            </Form.Row>
                                            <Form.Row>
                                                <CustomInput
                                                    col={values.marital_status === "2" ? "4" : "6"}
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'gender' })}
                                                    required
                                                    name="sex"
                                                    type="radio"
                                                    option={store.state.master.sex}
                                                    value={values.sex}
                                                    handleChange={(value) => {
                                                        setFieldValue('sex', value.target.value);
                                                        masterMaritalStatus = store.state.master.master_marital_status.slice();
                                                        if (values.sex) {
                                                            if (values.sex === 'male') {
                                                                masterMaritalStatus.splice('2', 1);
                                                            } else {
                                                                masterMaritalStatus.splice('3', 1);
                                                            }
                                                        }
                                                    }}
                                                    isValid={touched.sex && !errors.sex}
                                                    isInvalid={!!errors.sex}
                                                    error={(typeof errors.sex === 'object') ? store.dispatch({ type: 'translate', 'text': errors.sex.type }).toString().replace('$param$', errors.sex.param).replace('$param2$', errors.sex.param) : store.dispatch({ type: 'translate', 'text': errors.sex })}
                                                />
                                                <CustomInput
                                                    col={values.marital_status === "2" ? "4" : "6"}
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'marital_status' })}
                                                    required
                                                    name="marital_status"
                                                    type="radio"
                                                    option={masterMaritalStatus}
                                                    value={values.marital_status}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    isValid={touched.marital_status && !errors.marital_status}
                                                    isInvalid={!!errors.marital_status}
                                                    error={(typeof errors.marital_status === 'object') ? store.dispatch({ type: 'translate', 'text': errors.marital_status.type }).toString().replace('$param$', errors.marital_status.param).replace('$param2$', errors.marital_status.param) : store.dispatch({ type: 'translate', 'text': errors.marital_status })}
                                                />
                                                {values.marital_status === "2" ?
                                                    <CustomInput
                                                        col="4"
                                                        labelName={store.dispatch({ type: 'translate', 'text': 'spouse_name' })}
                                                        name="spouse_name"
                                                        type="text"
                                                        required={(values.marital_status === "2")}
                                                        minLength="3"
                                                        maxLength="100"
                                                        value={values.spouse_name}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        isValid={touched.spouse_name && !errors.spouse_name}
                                                        isInvalid={!!errors.spouse_name}
                                                        error={(typeof errors.spouse_name === 'object') ? store.dispatch({ type: 'translate', 'text': errors.spouse_name.type }).toString().replace('$param$', errors.spouse_name.param).replace('$param2$', errors.spouse_name.param) : store.dispatch({ type: 'translate', 'text': errors.spouse_name })}
                                                        placeholder="Spouse Name"
                                                    />
                                                    : ''}
                                            </Form.Row>
                                            <Form.Row>
                                                <CustomInput
                                                    col={values.religion !== "other" ? "6" : "4"}
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'mother_maiden_name' })}
                                                    required
                                                    name="mother_maiden_name"
                                                    type="text"
                                                    value={values.mother_maiden_name}
                                                    handleChange={(el) => {
                                                        let elVal = el.target.value ? el.target.value.toUpperCase() : "";
                                                        setFieldValue("mother_maiden_name", elVal);
                                                    }}
                                                    isValid={touched.mother_maiden_name && !errors.mother_maiden_name}
                                                    isInvalid={!!errors.mother_maiden_name}
                                                    error={(typeof errors.mother_maiden_name === 'object') ? store.dispatch({ type: 'translate', 'text': errors.mother_maiden_name.type }).toString().replace('$param$', errors.mother_maiden_name.param).replace('$param2$', errors.mother_maiden_name.param) : store.dispatch({ type: 'translate', 'text': errors.mother_maiden_name })}
                                                />
                                                <CustomInput
                                                    col={values.religion !== "other" ? "6" : "4"}
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'religion' })}
                                                    required
                                                    name="religion"
                                                    type="radio"
                                                    option={store.state.master.religion}
                                                    value={values.religion}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    isValid={touched.religion && !errors.religion}
                                                    isInvalid={!!errors.religion}
                                                    error={(typeof errors.religion === 'object') ? store.dispatch({ type: 'translate', 'text': errors.religion.type }).toString().replace('$param$', errors.religion.param).replace('$param2$', errors.religion.param) : store.dispatch({ type: 'translate', 'text': errors.religion })}
                                                />
                                                {values.religion === "other" ?
                                                    <CustomInput
                                                        col="4"
                                                        labelName={store.dispatch({ type: 'translate', 'text': 'religion_other' })}
                                                        name="religion_other"
                                                        type="text"
                                                        required={(values.religion === "other")}
                                                        maxLength="100"
                                                        pattern="^[A-Z a-z]+"
                                                        value={values.religion_other}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        isValid={touched.religion_other && !errors.religion_other}
                                                        isInvalid={!!errors.religion_other}
                                                        error={(typeof errors.religion_other === 'object') ? store.dispatch({ type: 'translate', 'text': errors.religion_other.type }).toString().replace('$param$', errors.religion_other.param).replace('$param2$', errors.religion_other.param) : store.dispatch({ type: 'translate', 'text': errors.religion_other })}
                                                        placeholder="Religion Other"
                                                    />
                                                    : ''}
                                            </Form.Row>
                                            <Form.Row>
                                                <CustomInput
                                                    col="6"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'educational_background' })}
                                                    name="educational_background"
                                                    type="select"
                                                    required
                                                    value={values.educational_background}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    placeholder={store.dispatch({ type: 'translate', 'text': 'placeholder_select' })}
                                                    error={(typeof errors.educational_background === 'object') ? store.dispatch({ type: 'translate', 'text': errors.educational_background.type }).toString().replace('$param$', errors.educational_background.param).replace('$param2$', errors.educational_background.param) : store.dispatch({ type: 'translate', 'text': errors.educational_background })}
                                                    touched={touched.educational_background}
                                                    option={store.state.master.master_educational_background_ksei}
                                                />
                                                <CustomInput
                                                    col="6"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'income_per_annum' })}
                                                    name="income_per_annum"
                                                    type="select"
                                                    required
                                                    value={values.income_per_annum}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    placeholder={store.dispatch({ type: 'translate', 'text': 'placeholder_select' })}
                                                    error={(typeof errors.income_per_annum === 'object') ? store.dispatch({ type: 'translate', 'text': errors.income_per_annum.type }).toString().replace('$param$', errors.income_per_annum.param).replace('$param2$', errors.income_per_annum.param) : store.dispatch({ type: 'translate', 'text': errors.income_per_annum })}
                                                    touched={touched.income_per_annum}
                                                    option={store.state.master.master_income_per_annum_ksei}
                                                />
                                            </Form.Row>
                                            <Form.Row>
                                                <CustomInput
                                                    col="6"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'source_of_fund' })}
                                                    name="source_of_fund"
                                                    type="select"
                                                    required
                                                    value={values.source_of_fund}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    placeholder={store.dispatch({ type: 'translate', 'text': 'placeholder_select' })}
                                                    error={(typeof errors.source_of_fund === 'object') ? store.dispatch({ type: 'translate', 'text': errors.source_of_fund.type }).toString().replace('$param$', errors.source_of_fund.param).replace('$param2$', errors.source_of_fund.param) : store.dispatch({ type: 'translate', 'text': errors.source_of_fund })}
                                                    touched={touched.source_of_fund}
                                                    option={store.state.master.master_source_of_fund}
                                                />
                                                {(values.source_of_fund && (values.source_of_fund.value === 1 || parseInt(values.source_of_fund) === 1)) ?
                                                    <CustomInput
                                                        col="6"
                                                        labelName={store.dispatch({ type: 'translate', 'text': 'source_of_fund_others' })}
                                                        name="source_of_fund_others"
                                                        type="text"
                                                        required={(values.source_of_fund.value === 1 || parseInt(values.source_of_fund) === 1)}
                                                        maxLength="100"
                                                        value={values.source_of_fund_others}
                                                        handleChange={handleChange}
                                                        isValid={touched.source_of_fund_others && !errors.source_of_fund_others}
                                                        isInvalid={!!errors.source_of_fund_others}
                                                        error={(typeof errors.source_of_fund_others === 'object') ? store.dispatch({ type: 'translate', 'text': errors.source_of_fund_others.type }).toString().replace('$param$', errors.source_of_fund_others.param).replace('$param2$', errors.source_of_fund_others.param) : store.dispatch({ type: 'translate', 'text': errors.source_of_fund_others })}
                                                        placeholder="Source of Fund"
                                                    /> : ''
                                                }
                                            </Form.Row>
                                            <Form.Row>
                                                <CustomInput
                                                    col="6"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'investment_objectives' })}
                                                    name="investment_objectives"
                                                    type="select"
                                                    value={values.investment_objectives}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    placeholder={store.dispatch({ type: 'translate', 'text': 'placeholder_select' })}
                                                    error={(typeof errors.investment_objectives === 'object') ? store.dispatch({ type: 'translate', 'text': errors.investment_objectives.type }).toString().replace('$param$', errors.investment_objectives.param).replace('$param2$', errors.investment_objectives.param) : store.dispatch({ type: 'translate', 'text': errors.investment_objectives })}
                                                    touched={touched.investment_objectives}
                                                    option={store.state.master.master_investment_objectives}
                                                />
                                                <CustomInput
                                                    col="6"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'asset_owner' })}
                                                    required
                                                    name="asset_owner"
                                                    type="radio"
                                                    option={store.state.master.asset_owner}
                                                    value={values.asset_owner}
                                                    handleChange={handleChange}
                                                    isValid={touched.asset_owner && !errors.asset_owner}
                                                    isInvalid={!!errors.asset_owner}
                                                    error={(typeof errors.asset_owner === 'object') ? store.dispatch({ type: 'translate', 'text': errors.asset_owner.type }).toString().replace('$param$', errors.asset_owner.param).replace('$param2$', errors.asset_owner.param) : store.dispatch({ type: 'translate', 'text': errors.asset_owner })}
                                                />
                                            </Form.Row>
                                            <div className={cx('row', styleRegis['wrap-btn'])}>
                                                <div className="container-btn col-md-12 text-center">
                                                    <div className='button-control'>
                                                        <ButtonCustom class="yellow-btn" value={store.dispatch({ type: 'translate', 'text': 'btn_prev' })} disabled={isSubmitting} onButtonClick={(value) => store.dispatch({ type: 'clickButton', field: 'prev' })} />
                                                        <ButtonCustom id="general-information-btn" class="blue-btn" value={store.dispatch({ type: 'translate', 'text': 'btn_next' })} type="submit" loading={isSubmitting} disabled={isSubmitting} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Fragment>
                                )}
                            </Formik>
                        )
                    }
                }
            </RootContext.Consumer>
        )
    }
}