import React, { Component, Fragment } from 'react';
import style from './StepForm.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import {RootContext} from '../Home';

export default class StepForm extends Component {
	render() {
		const currentStep = parseInt(this.props.currentStep);
		return (
            <RootContext.Consumer>
                {
                    store => {
                    	return (
							<Fragment>
								<div className={style['stepform-content'] + ' step-form'}>
									<ul>
										<li className={cx(currentStep >= 1 ? style['active'] : '', currentStep === 1 ? style['current'] : '', (currentStep-1) === 1 ? style['prev'] : '')} >
											{ currentStep > 1 ? <FontAwesomeIcon className={style['icon']} icon={faCheck} /> : '' }
											{store.dispatch({type:'translate', 'text': 'step_1'})}
										</li>
										<li className={cx(currentStep >= 2 ? style['active'] : '', currentStep === 2 ? style['current'] : '', (currentStep-1) === 2 ? style['prev'] : '')} >
											{ currentStep > 2 ? <FontAwesomeIcon className={style['icon']} icon={faCheck} /> : '' }
											{store.dispatch({type:'translate', 'text': 'step_2'})}
										</li>
										<li className={cx(currentStep >= 3 ? style['active'] : '', currentStep === 3 ? style['current'] : '', (currentStep-1) === 3 ? style['prev'] : '')} >
											{ currentStep > 3 ? <FontAwesomeIcon className={style['icon']} icon={faCheck} /> : '' }
											{store.dispatch({type:'translate', 'text': 'step_3'})}
										</li>
										<li className={cx(currentStep >= 4 ? style['active'] : '', currentStep === 4 ? style['current'] : '', (currentStep-1) === 4 ? style['prev'] : '')} >
											{ currentStep > 4 ? <FontAwesomeIcon className={style['icon']} icon={faCheck} /> : '' }
											{store.dispatch({type:'translate', 'text': 'step_4'})}
										</li>
										<li className={cx(currentStep >= 5 ? style['active'] : '', currentStep === 5 ? style['current'] : '', (currentStep-1) === 5 ? style['prev'] : '')} >
											{ currentStep > 5 ? <FontAwesomeIcon className={style['icon']} icon={faCheck} /> : '' }
											{store.dispatch({type:'translate', 'text': 'step_5'})}
										</li>
									</ul>
								</div>
							</Fragment>
                		)
                    }
                }
            </RootContext.Consumer>
		);
	}
}
