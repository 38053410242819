import React, { Fragment, useContext } from 'react';
import { Button } from 'react-bootstrap';
import style from './ButtonCustom.module.scss';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import {RootContext} from '../../Home';

const ButtonCustom = (props) => {
	const handleClick = (e) => {
		if (props.type !== 'submit') {
			e.preventDefault();
			props.onButtonClick(e.currentTarget.id);
		}
	}
	const store = useContext(RootContext);
	if (props.type === 'redirect') {
		return (
			<Fragment>
				<Link to={props.to}>
					<Button variant="link" style={props.style} className={ cx(style.btn, style['blue-btn'], style['redirect-btn']) }>{ props.value }</Button>
				</Link>
			</Fragment>
		);
	} else if (props.type === 'submit') {

		if (props.class === 'yellow-btn') {
			return (
				<Fragment>
					<Button id="back" style={props.style} variant="link" type="submit" className={ cx(style.btn,style[props.class]) } disabled={props.disabled} onClick={handleClick}>
						<FontAwesomeIcon icon={faAngleLeft} />
						{props.value}
					</Button>
				</Fragment>
			);
		} else if (props.class === 'blue-btn') {
			return (
				<Fragment>
					<Button id={props.id ? props.id : 'continue'} style={props.style} variant="link" type="submit" className={ cx(style.btn,style[props.class]) } disabled={props.disabled}  onClick={handleClick}>
						{props.loading ? ' ' + store.dispatch({type:'translate', 'text': 'loading'}) : props.value}
						{!props.loading ? <FontAwesomeIcon icon={faAngleRight} /> : ''}
					</Button>
					
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<Button id={props.id ? props.id : ''} style={props.style} variant="link" type="submit" className={ cx(style.btn, style['blue-btn'], style['redirect-btn']) } disabled={props.disabled}>
					{props.loading ? ' ' + store.dispatch({type:'translate', 'text': 'loading'}) : props.value}
					</Button>
				</Fragment>
			);
		}
	} else {
		if (props.class === 'yellow-btn') {
			return (
				<Fragment>
					<Button id="back" style={props.style} variant="link" className={ cx(style.btn,style[props.class]) } disabled={props.disabled} onClick={handleClick}>
						<FontAwesomeIcon icon={faAngleLeft} />
						{props.value}
					</Button>
				</Fragment>
			);
		} else if (props.class === 'blue-btn') {
			return (
				<Fragment>
					<Button id="continue" style={props.style} variant="link" className={ cx(style.btn,style[props.class]) } disabled={props.disabled}  onClick={handleClick}>
						{props.value}
						<FontAwesomeIcon icon={faAngleRight} />
					</Button>
					
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<Button variant="link" style={props.style} className={ cx(style.btn, style[props.styleCustom], style['btn-auto']) } disabled={props.disabled} onClick={handleClick}>
						{props.value}
					</Button>
				</Fragment>
			)
		}
	} 
}

export default ButtonCustom;