import React from 'react';
import './Language.scss';


const Language = (props) => {
	function handleClick(e) {
		e.preventDefault();
    	props.handleChange(e.currentTarget.getAttribute('lang'));
	}
	return (
		<div className="wrap-lang">
			<ul>
				<li className={props.currentLang === 'id' ? 'active' : ''}>
					<a href="/#" lang="id" onClick={handleClick}><span>ID</span></a>
				</li>
				<li className={props.currentLang === 'en' ? 'active' : ''}>
					<a href="/#" lang="en" onClick={handleClick}><span>EN</span></a>
				</li>
			</ul>
		</div>
	)
}

export default Language;