import React from "react";
import Home from "./Home";
import Axios from 'axios';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  async componentDidCatch(error, errorInfo) {
    if(error) {
        const link_api = (window.location.host === 'openaccount.nhsec.co.id') ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV;
        let userAgent = navigator.userAgent;
        await Axios.post(link_api + '/api/log-service', {ipAddress: '-', userAgent: userAgent, errorTitle: error.toString(), errorBody: errorInfo.componentStack });
    }
    
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

function App() {
  return (
    <ErrorBoundary>
      <Home />
    </ErrorBoundary>
  );
}

export default App;
