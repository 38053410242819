import React, { Component, Fragment } from 'react';
import ButtonCustom from '../components/mini/ButtonCustom';
import { Form, Col, Modal, Button } from 'react-bootstrap';
import { RootContext } from '../Home';
import cx from 'classnames';
import styleRegis from '../pages/RegistrationPage.module.scss';
import { Formik } from 'formik';
import validate from '../pages/validate';
import CustomInput from '../components/mini/CustomInput';
import * as Yup from "yup";
import SignaturePad from 'react-signature-canvas';
import $ from 'jquery';
import { isMobile } from "react-device-detect";
import ExampleSign from '../assets/example_sign.jpg';
import Cropper from 'react-easy-crop';
import getCroppedImg from './canvasUtils';

// import {Modal, Button} from 'react-bootstrap';
// import PDFViewer from 'pdf-viewer-reactjs';

// const CustomNavigation = (props) => {
//     return (
//         <div className='button-control'>
//             <ButtonCustom class="yellow-btn" value="Prev" disabled={props.page === 1 ? true : false} onButtonClick={props.handlePrevClick} />
//             <div style={{display:'inline-block'}}>Page {props.page} / {props.pages}</div>
//             <ButtonCustom class="blue-btn" value="Next" disabled={props.page === props.pages ? true : false} onButtonClick={props.handleNextClick} />
//         </div>
//     )
// }
function search(nameKey, myArray) {
    if (myArray) {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].value === nameKey) {
                return myArray[i];
            }
        }
    }
}

const link_url = (window.location.host === 'openaccount.nhsec.co.id') ? process.env.REACT_APP_LINK_URL_PROD : process.env.REACT_APP_LINK_URL_DEV;

class BankAccountForm extends Component {
    state = {
        // show: false,
        trimmedDataURL: null,
        changeSignature: false,
        tempImgSignature: '',
        dimensions: {
            width: '',
            height: '',
        },
        valueBankName: '',

        showModal: false,
        crop: { x: 0, y: 0 },
        zoom: 2,
        aspect: 7 / 3,
        croppedAreaPixels: null,
        imgCropResult: '',
    }

    handleModalClose = () => {
        this.setState({
            showModal: false
        });
    }

    handleModalShow = () => {
        this.setState({
            showModal: true
        });
    }

    onCropChange = crop => {
        this.setState({ crop })
    }
    onZoomChange = zoom => {
        this.setState({ zoom })
    }
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setCroppedAreaPixels(croppedAreaPixels);
    }
    setCroppedAreaPixels = (croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }
    cropImage = async () => {
        const croppedImage = await getCroppedImg(this.state.tempImgSignature.src, this.state.croppedAreaPixels)
        this.setState({
            tempImgSignature: {
                ...this.state.tempImgSignature,
                src: croppedImage
            },
            imgCropResult: croppedImage,
        });
        this.handleModalClose();
    }

    sigPad = {}
    clear = () => {
        this.sigPad.clear();
        $('#signature_raw').val('').change();
    }
    trim = () => {
        this.setState({
            trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png")
        }, () => {
            $('#signature_raw').val(this.state.trimmedDataURL).trigger('change').trigger('blur');
        });
    }
    componentDidMount() {
        this.setState({
            dimensions: {
                width: this.container.offsetWidth,
                height: this.container.offsetHeight + 100,
            },
        })

        if (typeof this.props.cur_bank_name === 'object') {
            this.setState({
                valueBankName: this.props.cur_bank_name.value,
            });
        } else {
            this.setState({
                valueBankName: this.props.cur_bank_name,
            });
        }
    }

    render() {
        return (
            <RootContext.Consumer>
                {
                    store => {
                        let company_name = '';
                        if (store.state.setting_web && store.state.setting_web.length > 0) {
                            company_name = store.state.setting_web.find(({ key }) => key === 'company_name').value;
                        }
                        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

                        const schema = !isMobile ? Yup.object().shape({
                            bank_name: Yup.object().required('error_valueMissing').nullable(),
                            bank_name_others: Yup.object().when("bank_name", {
                                is: bank => (bank && bank.value === 'other'),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                            rdn_bank: Yup.object().when("bank_name", {
                                is: bank => (bank && bank.value === 'other'),
                                then: Yup.object().required(
                                    'error_valueMissing'
                                ).nullable(),
                            }),
                        })
                            : '';

                        return (
                            <Formik
                                validate={(values) => validate(values, '#bank-account')}
                                validationSchema={schema}
                                onSubmit={values => {
                                    store.dispatch({ type: 'handleSubmit', field: 'registration_bank_account', values: values })
                                }}
                                initialValues={{
                                    bank_name: store.state.registration.bank_name,
                                    bank_name_others: store.state.registration.bank_name_others,
                                    rdn_bank: store.state.registration.rdn_bank,
                                    bank_book_file: store.state.registration.bank_book_file,
                                    account_number: store.state.registration.account_number,
                                    // bank_bic_code: store.state.registration.bank_bic_code,
                                    account_holder_name: store.state.registration.account_holder_name,
                                    npwp_number: store.state.registration.npwp_number,
                                    npwp_registration_date: store.state.registration.npwp_registration_date,
                                    npwp_file: store.state.registration.npwp_file,
                                    account_currency: store.state.registration.account_currency,
                                    signature_raw: store.state.registration.signature_raw,
                                    bca_agree1: store.state.registration.bca_agree1,
                                    bca_agree2: store.state.registration.bca_agree2,
                                    bca_agree3: store.state.registration.bca_agree3,
                                    cimb_agree1: store.state.registration.cimb_agree1,
                                    cimb_agree2: store.state.registration.cimb_agree2,
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    isSubmitting,
                                    touched,
                                    isValid,
                                    errors,
                                    setFieldValue,
                                    setFieldTouched,
                                }) => (
                                    <Fragment>
                                        <Form id="bank-account" className="form" validated={errors ? false : true} noValidate onSubmit={handleSubmit}>
                                            {
                                                /*
                                                <Form.Row>
                                                    <pre
                                                      className="col-lg-12"
                                                      style={{
                                                        background: '#f6f8fa',
                                                        fontSize: '.65rem',
                                                        padding: '.5rem',
                                                        textAlign: 'left',
                                                        width: '750px',
                                                      }}
                                                    >
                                                      <strong>props</strong> ={' '}
                                                      {JSON.stringify(values, null, 2)}
                                                      <br/>
                                                      <strong>errors</strong> ={' '}
                                                      {JSON.stringify(errors, null, 2)}
                                                      <br/>
                                                      <strong>touched</strong> ={' '}
                                                      {JSON.stringify(touched, null, 2)}
                                                      <br/>
                                                    </pre>
                                                </Form.Row>
                                                */
                                            }
                                            <Form.Row>
                                                <CustomInput
                                                    col="12"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'bank_name_exists' })}
                                                    name="bank_name"
                                                    type="select"
                                                    required
                                                    value={values.bank_name}
                                                    onChange={(name, value) => {
                                                        setFieldValue(name, value);

                                                        // reset field yang lain
                                                        setFieldValue('account_holder_name', '');
                                                        setFieldValue('account_number', '');
                                                        setFieldValue('rdn_bank', '');

                                                        if ((typeof value === 'object' && value.value === 'bca') || value === 'bca') {
                                                            this.setState({
                                                                valueBankName: (typeof value === 'object') ? value.value : value
                                                            })
                                                            if (!isMobile) {
                                                                setFieldValue('rdn_bank', search('bca', store.state.master.rdn_bank));
                                                            } else {
                                                                setFieldValue('rdn_bank', 'bca');
                                                            }
                                                        }
                                                        if ((typeof value === 'object' && value.value === 'cimb') || value === 'cimb') {
                                                            this.setState({
                                                                valueBankName: (typeof value === 'object') ? value.value : value
                                                            })

                                                            if (!isMobile) {
                                                                setFieldValue('rdn_bank', search('cimb', store.state.master.rdn_bank));
                                                            } else {
                                                                setFieldValue('rdn_bank', 'cimb');
                                                            }
                                                        }
                                                        if ((typeof value === 'object' && value.value === 'bri') || value === 'bri') {
                                                            this.setState({
                                                                valueBankName: (typeof value === 'object') ? value.value : value
                                                            })

                                                            if (!isMobile) {
                                                                setFieldValue('rdn_bank', search('bri', store.state.master.rdn_bank));
                                                            } else {
                                                                setFieldValue('rdn_bank', 'bri');
                                                            }
                                                        }                                                        
                                                    }}
                                                    onBlur={setFieldTouched}
                                                    placeholder={store.dispatch({ type: 'translate', 'text': 'placeholder_select' })}
                                                    error={(typeof errors.bank_name === 'object') ? store.dispatch({ type: 'translate', 'text': errors.bank_name.type }).toString().replace('$param$', errors.bank_name.param).replace('$param2$', errors.bank_name.param2) : store.dispatch({ type: 'translate', 'text': errors.bank_name })}
                                                    touched={touched.bank_name}
                                                    option={store.state.master.bank}
                                                />
                                            </Form.Row>
                                            {
                                                ((typeof values.bank_name === 'object' && values.bank_name.value === 'other') || values.bank_name === 'other') ?
                                                    <Form.Row>
                                                        <CustomInput
                                                            col="12"
                                                            labelName={store.dispatch({ type: 'translate', 'text': 'bank_name' })}
                                                            name="bank_name_others"
                                                            type="select"
                                                            required
                                                            value={values.bank_name_others}
                                                            onChange={(name, value) => {
                                                                setFieldValue(name, value);
                                                                
                                                                setFieldValue('account_holder_name', '');
                                                                setFieldValue('account_number', '');
                                                                setFieldValue('rdn_bank', '');
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            placeholder={store.dispatch({ type: 'translate', 'text': 'placeholder_select' })}
                                                            error={(typeof errors.bank_name_others === 'object') ? store.dispatch({ type: 'translate', 'text': errors.bank_name_others.type }).toString().replace('$param$', errors.bank_name_others.param).replace('$param2$', errors.bank_name_others.param2) : store.dispatch({ type: 'translate', 'text': errors.bank_name_others })}
                                                            touched={touched.bank_name_others}
                                                            option={store.state.master.bank_list}
                                                        />
                                                    </Form.Row>
                                                    : ''
                                            }
                                            <Form.Row>
                                                <CustomInput
                                                    col="6"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'account_holder_name' })}
                                                    name="account_holder_name"
                                                    maxLength="50"
                                                    required
                                                    type="text"
                                                    pattern="^[A-Z a-z]+"
                                                    value={values.account_holder_name}
                                                    handleChange={
                                                        (el) => {
                                                            let elVal = el.target.value ? el.target.value.toUpperCase() : "";
                                                            setFieldValue("account_holder_name", elVal);
                                                        }
                                                    }
                                                    isValid={touched.account_holder_name && !errors.account_holder_name}
                                                    isInvalid={!!errors.account_holder_name}
                                                    error={(typeof errors.account_holder_name === 'object') ? store.dispatch({ type: 'translate', 'text': errors.account_holder_name.type }).toString().replace('$param$', errors.account_holder_name.param).replace('$param2$', errors.account_holder_name.param2) : store.dispatch({ type: 'translate', 'text': errors.account_holder_name })}
                                                />
                                                <CustomInput
                                                    col="6"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'account_number' })}
                                                    name="account_number"
                                                    type="text"
                                                    maxLength={((typeof values.bank_name === 'object' && values.bank_name.value === 'bca') || values.bank_name === 'bca') ? '10' : '16'}
                                                    minLength="10"
                                                    required
                                                    pattern="^[0-9]*$"
                                                    value={values.account_number}
                                                    handleChange={handleChange}
                                                    isValid={touched.account_number && !errors.account_number}
                                                    isInvalid={!!errors.account_number}
                                                    error={(typeof errors.account_number === 'object') ? store.dispatch({ type: 'translate', 'text': errors.account_number.type }).toString().replace('$param$', errors.account_number.param).replace('$param2$', errors.account_number.param2) : store.dispatch({ type: 'translate', 'text': errors.account_number })}
                                                />
                                            </Form.Row>
                                            {
                                                ((typeof values.bank_name === 'object' && values.bank_name.value === 'other') || values.bank_name === 'other') ?
                                                    <Form.Row>
                                                        <CustomInput
                                                            col="12"
                                                            labelName="RDN Bank"
                                                            name="rdn_bank"
                                                            type="select"
                                                            required
                                                            value={values.rdn_bank}
                                                            onChange={(name, value) => {
                                                                setFieldValue(name, value);
                                                                this.setState({
                                                                    'valueBankName': (typeof value === 'object') ? value.value : value
                                                                })
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            placeholder={store.dispatch({ type: 'translate', 'text': 'placeholder_select' })}
                                                            error={(typeof errors.rdn_bank === 'object') ? store.dispatch({ type: 'translate', 'text': errors.rdn_bank.type }).toString().replace('$param$', errors.rdn_bank.param).replace('$param2$', errors.rdn_bank.param2) : store.dispatch({ type: 'translate', 'text': errors.rdn_bank })}
                                                            touched={touched.rdn_bank}
                                                            option={store.state.master.rdn_bank_option}
                                                        />
                                                    </Form.Row>
                                                    : ''
                                            }
                                            <Form.Row>
                                                <div className="information-bca col-12">
                                                    <strong>{store.dispatch({ type: 'translate', 'text': 'label_information' })}: </strong>
                                                    {/* {renderHTML(store.dispatch({ 'type': 'translate', 'text': 'information_bca' }))} */}
                                                    {renderHTML(store.dispatch({ 'type': 'translate', 'text': 'information_rdn' }))}
                                                </div>
                                                {
                                                    // (this.state.valueBankName) ? 
                                                    // (this.state.valueBankName === 'bca') ?
                                                    // <div className="information-bca">
                                                    //     <strong>{store.dispatch({type:'translate', 'text': 'label_information'})}: </strong>{renderHTML(store.dispatch({'type':'translate', 'text': 'information_bca'}))}
                                                    // </div>
                                                    // :
                                                    // <div className="information-bca">
                                                    //     <strong>{store.dispatch({type:'translate', 'text': 'label_information'})}: </strong>{renderHTML(store.dispatch({'type':'translate', 'text': 'information_nonbca'}))}
                                                    // </div>
                                                    // : ''
                                                }
                                            </Form.Row>
                                            <Form.Row>
                                                <CustomInput
                                                    col="6"
                                                    labelName={store.dispatch({ type: 'translate', 'text': 'bank_book_file' })}
                                                    name="bank_book_file"
                                                    type="file"
                                                    accept="image/jpg,image/png,image/jpeg"
                                                    capture
                                                    btnMobile={true}
                                                    required={!store.state.registration.bank_book_path && !values.bank_book_file ? true : false}
                                                    exists={store.state.registration.bank_book_path}
                                                    handleChange={(file, imgTemp) => setFieldValue('bank_book_file', file)}
                                                    isValid={touched.bank_book_file && !errors.bank_book_file}
                                                    isInvalid={!!errors.bank_book_file}
                                                    error={(typeof errors.bank_book_file === 'object') ? store.dispatch({ type: 'translate', 'text': errors.bank_book_file.type }).toString().replace('$param$', errors.bank_book_file.param).replace('$param2$', errors.bank_book_file.param2) : store.dispatch({ type: 'translate', 'text': errors.bank_book_file })}
                                                />
                                            </Form.Row>
                                            {
                                                ((typeof values.rdn_bank === 'object' && values.rdn_bank.value === 'mandiri') || values.rdn_bank === 'mandiri') ?
                                                    <Form.Row>
                                                        <CustomInput
                                                            col="4"
                                                            labelName={store.dispatch({ type: 'translate', 'text': 'npwp_number' })}
                                                            name="npwp_number"
                                                            type="text"
                                                            maxLength="15"
                                                            minLength="15"
                                                            pattern="^[0-9]*$"
                                                            required={true}
                                                            value={values.npwp_number}
                                                            handleChange={handleChange}
                                                            isValid={touched.npwp_number && !errors.npwp_number}
                                                            isInvalid={!!errors.npwp_number}
                                                            error={(typeof errors.npwp_number === 'object') ? store.dispatch({ type: 'translate', 'text': errors.npwp_number.type }).toString().replace('$param$', errors.npwp_number.param).replace('$param2$', errors.npwp_number.param2) : store.dispatch({ type: 'translate', 'text': errors.npwp_number })}
                                                        />
                                                        <CustomInput
                                                            col="4"
                                                            labelName={store.dispatch({ type: 'translate', 'text': 'npwp_file' })}
                                                            name="npwp_file"
                                                            type="file"
                                                            accept="image/jpg,image/png,image/jpeg"
                                                            capture
                                                            required={!store.state.registration.npwp_path && !values.npwp_file ? true : false}
                                                            exists={store.state.registration.npwp_path}
                                                            handleChange={(file) => setFieldValue('npwp_file', file)}
                                                            isValid={touched.npwp_file && !errors.npwp_file}
                                                            isInvalid={!!errors.npwp_file}
                                                            error={(typeof errors.npwp_file === 'object') ? store.dispatch({ type: 'translate', 'text': errors.npwp_file.type }).toString().replace('$param$', errors.npwp_file.param).replace('$param2$', errors.npwp_file.param2) : store.dispatch({ type: 'translate', 'text': errors.npwp_file })}
                                                        />
                                                        <CustomInput
                                                            col="4"
                                                            labelName={store.dispatch({ type: 'translate', 'text': 'npwp_registration_date' })}
                                                            name="npwp_registration_date"
                                                            type="date"
                                                            required={true}
                                                            dateType="registerdate"
                                                            value={values.npwp_registration_date}
                                                            handleChange={date => setFieldValue('npwp_registration_date', date)}
                                                            isValid={touched.npwp_registration_date && !errors.npwp_registration_date}
                                                            isInvalid={!!errors.npwp_registration_date}
                                                            error={(typeof errors.npwp_registration_date === 'object') ? store.dispatch({ type: 'translate', 'text': errors.npwp_registration_date.type }).toString().replace('$param$', errors.npwp_registration_date.param).replace('$param2$', errors.npwp_registration_date.param2) : store.dispatch({ type: 'translate', 'text': errors.npwp_registration_date })}
                                                            placeholder="DD/MM/YYYY"
                                                        />
                                                    </Form.Row>
                                                    : ''
                                            }

                                            <Form.Row>
                                                <Form.Group as={Col} lg={12} className="form-group" ref={el => (this.container = el)}>
                                                    <Form.Label className="form-label">
                                                        {store.dispatch({ type: 'translate', 'text': 'signature' })}
                                                        <Form.Text className="required">* </Form.Text>
                                                        <Form.Text className="info">{store.dispatch({ type: 'translate', 'text': 'signature_info' })}</Form.Text>
                                                    </Form.Label>
                                                    <input id="signature_raw" type="text"
                                                        style={{ display: 'none' }}
                                                        name="signature_raw"
                                                        value={values.signature_raw}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required={!store.state.registration.signature_path || this.state.changeSignature}
                                                        className="form-control"
                                                    />
                                                    {
                                                        errors.signature_raw && !values.signature_raw && <div className="invalid-feedback">{(typeof errors.signature_raw === 'object') ? store.dispatch({ type: 'translate', 'text': errors.signature_raw.type }).toString().replace('$param$', errors.signature_raw.param).replace('$param2$', errors.signature_raw.param2) : store.dispatch({ type: 'translate', 'text': errors.signature_raw })}</div>
                                                    }

                                                    <div className="wrap-signature">
                                                        {
                                                            store.state.registration.signature_path && !this.state.tempImgSignature && !this.state.changeSignature ?
                                                                <Fragment>
                                                                    <img alt={store.state.registration.first_name} src={link_url + store.state.registration.signature_path.split("/home/openaccount/").pop() + '?show=' + Math.floor(Math.random() * 200)} className="img-thumbnail" />
                                                                    <div className="navigate-signature-exists">
                                                                        <ButtonCustom value={store.dispatch({ type: 'translate', 'text': 'btn_change' })} styleCustom="blue-btn" onButtonClick={(value) => this.setState({ changeSignature: true })} />
                                                                    </div>
                                                                </Fragment>
                                                                :
                                                                this.state.tempImgSignature && !this.state.changeSignature ?
                                                                    <Fragment>
                                                                        <img alt={this.state.tempImgSignature.alt} src={this.state.tempImgSignature.src} className="img-thumbnail" />
                                                                        <div className="navigate-signature-exists">
                                                                            <ButtonCustom value={store.dispatch({ type: 'translate', 'text': 'btn_change' })} styleCustom="blue-btn" onButtonClick={(value) => { this.setState({ changeSignature: true, tempImgSignature: '' }); setFieldValue('signature_raw', ''); }} />
                                                                        </div>
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <SignaturePad
                                                                            penColor='black'
                                                                            canvasProps={{
                                                                                width: this.state.dimensions.width - 30,
                                                                                height: 250,
                                                                                className: 'sigCanvas',
                                                                            }}
                                                                            onEnd={(value) => {
                                                                                setFieldValue('signature_raw', this.sigPad.getTrimmedCanvas().toDataURL("image/png"));
                                                                            }
                                                                            }
                                                                            ref={(ref) => { this.sigPad = ref }}
                                                                        />
                                                                        <div className="navigate-signature">
                                                                            <ButtonCustom value={store.dispatch({ type: 'translate', 'text': 'btn_clear' })} styleCustom="blue-btn" onButtonClick={(value) => { this.clear(); setFieldValue('signature_raw', ''); }} />
                                                                            {
                                                                                store.state.registration.signature_path ?
                                                                                    <ButtonCustom value={store.dispatch({ type: 'translate', 'text': 'btn_cancel' })} styleCustom="yellow-btn" onButtonClick={(value) => { this.setState({ changeSignature: false }); setFieldValue('signature_raw', '') }} />
                                                                                    : ''
                                                                            }
                                                                            <input id="sig-choose" type="file"
                                                                                accept="image/jpg,image/png,image/jpeg" capture name="signature_file"
                                                                                style={{ display: 'none' }}
                                                                                onChange={(event) => {
                                                                                    if (event.currentTarget.files.length > 0) {
                                                                                        const reader = new FileReader();
                                                                                        const name = event.currentTarget.files[0].name;
                                                                                        reader.onloadend = () => {
                                                                                            if (reader.readyState === 2) {
                                                                                                this.setState({
                                                                                                    tempImgSignature: {
                                                                                                        src: reader.result,
                                                                                                        alt: name
                                                                                                    },
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                        reader.readAsDataURL(event.currentTarget.files[0]);
                                                                                        // setFieldValue('signature_raw', event.currentTarget.files[0]);
                                                                                        // setFieldTouched('signature_raw', true);
                                                                                        this.setState({ changeSignature: false });
                                                                                        this.handleModalShow();
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            <ButtonCustom style={{ float: 'right' }} value={store.dispatch({ type: 'translate', 'text': 'btn_choosefile' })} styleCustom="blue-btn" onButtonClick={(value) => { document.getElementById('sig-choose').click() }} />
                                                                        </div>
                                                                    </Fragment>
                                                        }
                                                        {(this.state.imgCropResult && this.state.imgCropResult !== values.signature_raw) ? setFieldValue('signature_raw', this.state.imgCropResult) : ''}
                                                        <Modal size="lg" centered show={this.state.showModal} onHide={this.handleModalClose} backdrop="static" keyboard={false}>
                                                            <Modal.Body>
                                                                <div style={{ position: 'relative', display: 'block', width: '100%', height: '500px' }}>
                                                                    <Cropper
                                                                        image={this.state.tempImgSignature.src}
                                                                        crop={this.state.crop}
                                                                        zoom={this.state.zoom}
                                                                        aspect={this.state.aspect}
                                                                        onCropChange={this.onCropChange}
                                                                        onCropComplete={this.onCropComplete}
                                                                        onZoomChange={this.onZoomChange}
                                                                    />
                                                                </div>
                                                                <div style={{
                                                                    padding: '20px 0',
                                                                }}>
                                                                    <Form.Control type="range" value={this.state.zoom} onChange={(e) => this.onZoomChange(e.currentTarget.value)} custom min="1" max="3" step="0.1" />
                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="primary" onClick={() => { this.cropImage(); console.log(this.state.imgCropResult) }}>Crop</Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </div>
                                                    <div className="wrap-example sign">
                                                        <Form.Text className="info">
                                                            <small>
                                                                <strong>{store.dispatch({ type: 'translate', 'text': 'label_attention' })}: </strong>
                                                                {renderHTML(store.dispatch({ type: 'translate', 'text': 'example_sign_info_text' }))}
                                                            </small>
                                                        </Form.Text>
                                                        <img src={ExampleSign}
                                                            alt="example"
                                                            className="img-thumbnail"
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Form.Row>

                                            {
                                                (this.state.valueBankName && this.state.valueBankName === 'bca') ?
                                                    <Form.Row>
                                                        <CustomInput
                                                            col="12"
                                                            required
                                                            name="bca_agree1"
                                                            type="checkbox"
                                                            typeCheck="single"
                                                            value={values.bca_agree1}
                                                            option={[{ value: 0, label: store.dispatch({ type: 'translate', 'text': 'bca_agree1_a' }) + company_name + store.dispatch({ type: 'translate', 'text': 'bca_agree1_b' }) }]}
                                                            handleChange={handleChange}
                                                            isValid={touched.bca_agree1 && !errors.bca_agree1}
                                                            isInvalid={!!errors.bca_agree1}
                                                            error={(typeof errors.bca_agree1 === 'object') ? store.dispatch({ type: 'translate', 'text': errors.bca_agree1.type }).toString().replace('$param$', errors.bca_agree1.param).replace('$param2$', errors.bca_agree1.param2) : store.dispatch({ type: 'translate', 'text': errors.bca_agree1 })}
                                                        />
                                                        <CustomInput
                                                            col="12"
                                                            required
                                                            name="bca_agree2"
                                                            type="checkbox"
                                                            typeCheck="single"
                                                            value={values.bca_agree2}
                                                            option={[{ value: 0, label: (<div>{store.dispatch({ type: 'translate', 'text': 'bca_agree2' })} <a target="_blank" rel="noopener noreferrer" href={store.state.registration.npwp_number ? "/viewer/bca-statement/?n=1" : "/viewer/bca-statement/?n=2"} style={{ 'fontWeight': 'bold', 'fontStyle': 'italic', 'color': '#0172ba', 'textDecoration': 'none', 'paddingBottom': '1px', 'borderBottom': '1px solid transparent', 'transition': 'all 0.5s' }}>{store.dispatch({ type: 'translate', 'text': 'state_here' })}</a></div>) }]}
                                                            handleChange={handleChange}
                                                            isValid={touched.bca_agree2 && !errors.bca_agree2}
                                                            isInvalid={!!errors.bca_agree2}
                                                            error={(typeof errors.bca_agree2 === 'object') ? store.dispatch({ type: 'translate', 'text': errors.bca_agree2.type }).toString().replace('$param$', errors.bca_agree2.param).replace('$param2$', errors.bca_agree2.param2) : store.dispatch({ type: 'translate', 'text': errors.bca_agree2 })}
                                                        />
                                                        <CustomInput
                                                            col="12"
                                                            required
                                                            name="bca_agree3"
                                                            type="checkbox"
                                                            typeCheck="single"
                                                            value={values.bca_agree3}
                                                            option={[{ value: 0, label: (<div>{store.dispatch({ type: 'translate', 'text': 'bca_agree3' })}<a target="_blank" href="/viewer/bca-provision" style={{ 'fontWeight': 'bold', 'fontStyle': 'italic', 'color': '#0172ba', 'textDecoration': 'none', 'paddingBottom': '1px', 'borderBottom': '1px solid transparent', 'transition': 'all 0.5s' }}>{store.dispatch({ type: 'translate', 'text': 'state_here' })}</a></div>) }]}
                                                            handleChange={handleChange}
                                                            isValid={touched.bca_agree3 && !errors.bca_agree3}
                                                            isInvalid={!!errors.bca_agree3}
                                                            error={(typeof errors.bca_agree3 === 'object') ? store.dispatch({ type: 'translate', 'text': errors.bca_agree3.type }).toString().replace('$param$', errors.bca_agree3.param).replace('$param2$', errors.bca_agree3.param2) : store.dispatch({ type: 'translate', 'text': errors.bca_agree3 })}
                                                        />
                                                    </Form.Row>
                                                    : ''

                                            }

                                            { /* CIMB AGREEMENT CHECKBOX */ }
                                            {
                                                (this.state.valueBankName && this.state.valueBankName === 'cimb') ?
                                                    <Form.Row>
                                                        <CustomInput
                                                            col="12"
                                                            required
                                                            name="cimb_agree1"
                                                            type="checkbox"
                                                            typeCheck="single"
                                                            value={values.cimb_agree1}
                                                            option={[{ value: 0, label: store.dispatch({ type: 'translate', 'text': 'cimb_agree1_a' }) + company_name + store.dispatch({ type: 'translate', 'text': 'cimb_agree1_b' }) }]}
                                                            handleChange={handleChange}
                                                            isValid={touched.cimb_agree1 && !errors.cimb_agree1}
                                                            isInvalid={!!errors.cimb_agree1}
                                                            error={(typeof errors.cimb_agree1 === 'object') ? store.dispatch({ type: 'translate', 'text': errors.cimb_agree1.type }).toString().replace('$param$', errors.cimb_agree1.param).replace('$param2$', errors.cimb_agree1.param2) : store.dispatch({ type: 'translate', 'text': errors.cimb_agree1 })}
                                                        />
                                                        <CustomInput
                                                            col="12"
                                                            required
                                                            name="cimb_agree2"
                                                            type="checkbox"
                                                            typeCheck="single"
                                                            value={values.cimb_agree2}
                                                            option={[{ value: 0, label: (<div>{store.dispatch({ type: 'translate', 'text': 'cimb_agree2' })}<a target="_blank" href="/viewer/cimb-kpupr" style={{ 'fontWeight': 'bold', 'fontStyle': 'italic', 'color': '#0172ba', 'textDecoration': 'none', 'paddingBottom': '1px', 'borderBottom': '1px solid transparent', 'transition': 'all 0.5s' }}>{store.dispatch({ type: 'translate', 'text': 'state_here' })}</a></div>) }]}
                                                            handleChange={handleChange}
                                                            isValid={touched.cimb_agree2 && !errors.cimb_agree2}
                                                            isInvalid={!!errors.cimb_agree2}
                                                            error={(typeof errors.cimb_agree2 === 'object') ? store.dispatch({ type: 'translate', 'text': errors.cimb_agree2.type }).toString().replace('$param$', errors.cimb_agree2.param).replace('$param2$', errors.cimb_agree2.param2) : store.dispatch({ type: 'translate', 'text': errors.cimb_agree2 })}
                                                        />
                                                    </Form.Row>
                                                    : ''

                                            }

                                            <div className={cx('row', styleRegis['wrap-btn'])}>
                                                <div className="container-btn col-md-12 text-center">
                                                    <div className='button-control'>
                                                        <ButtonCustom class="yellow-btn" value={store.dispatch({ type: 'translate', 'text': 'btn_prev' })} onButtonClick={(value) => store.dispatch({ type: 'clickButton', field: 'prev' })} />
                                                        <ButtonCustom id="bank-information-btn" class="blue-btn" value={store.dispatch({ type: 'translate', 'text': 'btn_finish' })} loading={store.state.next_action} type="submit" disabled={store.state.next_action} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Fragment>
                                )}
                            </Formik>

                        )
                    }
                }
            </RootContext.Consumer>
        )
    }
}

export default props => (
    <RootContext.Consumer>
        {
            store => {
                return (
                    <BankAccountForm {...props} cur_bank_name={store.state.registration.rdn_bank} />
                )
            }
        }
    </RootContext.Consumer>
)